<template>
  <v-container>
    <Preloader overlay v-if="userLoading" />
    <div class="headline mb-6 mt-1">
      {{ $t('message.admin.update_user') }}
    </div>
    <UserForm
      v-if="!userLoading"
      :data="getUser"
      @submit="updateUser"
      @cancel="cancel"
    />
  </v-container>
</template>

<script>
import UserForm from '@components/Shared/Forms/UserForm.vue';
import Preloader from '@components/Shared/Preloader.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'EditUser',
  components: {
    UserForm,
    Preloader,
  },
  props: {},
  computed: {
    ...mapGetters({
      userLoading: 'user/isLoading',
      user: 'user/getUser',
    }),
    getUser() {
      if (this.user) {
        return {
          role_ids: this.user.roles,
          ...this.user,
        };
      }
      return null;
    },
  },
  data() {
    return {};
  },
  created() {
    this.$api.users.getUser(this.$route.params.id);
  },
  methods: {
    async updateUser(formDate) {
      await this.$api.users.updateUsers(formDate, formDate.id);
      this.pushToUsers();
    },
    cancel() {
      this.pushToUsers();
    },
    pushToUsers() {
      this.$router.push('/admin/users');
    },
  },
};
</script>
