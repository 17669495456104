<template>
    <v-form ref="form">
      <div class="row">
        <div class="col ">
          <div class="form-group">
            <v-text-field
              v-model="formData.email"
              id="email"
              required
              :rules="[_rules.email]"
              outlined
            />
            <label class="label" for="email">
              {{$t('message.email')}}
              <i class="warning-icon">!</i>
            </label>
          </div>
        </div>
        <div class="col ">
          <div class="form-group">
            <v-text-field
              v-model="formData.name"
              id="name"
              required
              :rules="[_rules.required]"
              outlined
            />
            <label class="label" for="name">
              {{$t('message.name')}}
              <i class="warning-icon">!</i>
            </label>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col ">
          <div class="form-group">
            <v-text-field
              v-model="formData.surname"
              id="surname"
              required
              :rules="[_rules.required]"
              outlined
            />
            <label class="label" for="surname">
              {{$t('message.surname')}}
              <i class="warning-icon">!</i>
            </label>
          </div>
        </div>
        <div class="col ">
          <div class="form-group">
            <v-text-field
              v-model="formData.middle_name"
              id="middle"
              outlined
            />
            <label class="label" for="middle">
              {{ $t('message.admin.user_middlename') }}
              <i class="warning-icon">!</i>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col ">
          <div class="form-group">
            <v-text-field
              v-model="formData.e_klass_personal_code"
              id="e_klass_personal_code"
              :rules="[_rules.required]"
              outlined
            />
            <label class="label" for="e_klass_personal_code">
              {{ $t('message.admin.personal_code') }}
              <i class="warning-icon">!</i>
            </label>
          </div>
        </div>
        <div class="col ">
          <div class="form-group">
            <v-text-field
              v-model="formData.phone_number"
              :rules="[_rules.phone]"
              id="phone_number"
              outlined
            />
            <label class="label" for="phone_number">
              {{ $t('message.admin.user_phone_number') }}
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <v-select
              class="select_theme_default"
              v-model="formData.role_ids"
              :items="filtredRoles"
              :rules="[_rules.multiSelectRequired]"
              item-value="id"
              outlined
              multiple
            >
              <template slot="item" aria-selected="false" slot-scope="data">
                <div>
                  {{ data.item.title }}
                </div>
              </template>
              <template slot="selection" slot-scope="data">
                {{ data.item.title }}
              </template>
            </v-select>
            <label class="label" for="status">
              Select Role
              <i class="warning-icon">!</i>
            </label>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <v-select
              v-model="formData.status"
              id="status"
              :items="statuses"
              required
              :rules="[_rules.selectRequired]"
              outlined
            >
              <template slot="item" slot-scope="data">
                {{ data.item.alias }}
              </template>
              <template slot="selection" slot-scope="data">
                {{ data.item.alias }}
              </template>
            </v-select>
            <label class="label" for="status">
              User status
              <i class="warning-icon">!</i>
            </label>
          </div>
        </div>
      </div>
      <div class="form-control">
        <div class="form-control__left">
          <button class="m-button" @click.prevent="cancel" >
            {{$t('message.cancel')}}
          </button>
        </div>
        <div class="form-control__right">
          <button
            @click.prevent="submit"
            class="m-button m-button_success">
            {{ $t('message.submit') }}
          </button>
        </div>
      </div>
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@helpers/index';
import rulesMixin from '@/mixins/Form/rules';

export default {
  mixins: [
    rulesMixin,
  ],
  props: {
    data: {
      type: Object,
      default: () => ({
        name: '',
        email: '',
        surname: '',
        middle_name: '',
        e_klass_personal_code: '',
        role_ids: [],
        phone_number: '',
        status: 'active',
      }),
    },
  },
  computed: {
    ...mapGetters({
      roles: 'roles/getRoles',
    }),
    filtredRoles() {
      if (!this.formData.role_ids.length) return this.roles;
      return this.roles.filter(role => this.formData.role_ids
        .some(selectRole => selectRole.id !== role.id));
    },
  },
  data: () => ({
    formData: {
      name: '',
      email: '',
      surname: '',
      middle_name: '',
      e_klass_personal_code: '',
      role_ids: [],
      phone_number: '',
      status: 'active',
    },
    statuses: [
      { value: true, name: 'active', alias: 'Active' },
      { value: false, name: 'archived', alias: 'Archived' },
    ],
  }),
  created() {
    this.setData();
  },
  methods: {
    setData() {
      const status = this.statuses.find(item => item.name === this.data.status);
      const roleIds = Helpers.convertArrayObjectToArrayParam(this.data.role_ids, 'id');
      const data = { ...this.data, status: status.value, role_ids: roleIds };
      this.formData = Object.assign(this.formData, data);
    },
    rolesChanged(elem) {
      this.formData.role_ids.push(elem);
    },
    roleRemove(id) {
      this.formData.role_ids = this.formData.role_ids.filter(item => item.id !== id);
    },
    submit() {
      if (!this.formValidate(this.$refs.form)) return;
      this.$emit('submit', this.formData);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<!-- TODO Create Global style for input -->
<style lang="scss">
  .form-group {
    .v-text-field {
      .v-input__append-inner {
        margin-top: 9px;
        i {
          color: #969696;
        }
      }
      .v-text-field__details {
        margin-bottom: 5px;
        padding-left: 0;
      }
      .v-input__control .v-input__slot {
        padding: 0;
        min-height: 40px;
        box-shadow: none;
        input {
          padding: 0 12px;
          font-size: 14px;
          height: 100%;
        }
      }
      fieldset {
        border-color: $elementColor;
        border-width: 1px;
      }
      &.v-input--is-focused {
        fieldset {
          border-color: $accentColor;
        }
      }
      &.error--text {
        fieldset {
          border-color: $dangerColor;
        }
      }
    }
    .v-select {
      .v-select__slot {
        .v-select__selections {
          padding: 8px 12px !important;
          font-size: 14px;
          input {
            padding: 0;
          }
          .v-select__selection {
            margin: 3px 0;
          }
        }
      }
    }
  }

  .form-date-group {
    display: flex;
    flex-flow: row wrap;
  }

  .form-date-group__item {
    width: 25%;
    &.form-single-date {
      width: 40%;
    }
    &:not(:nth-child(3)) {
      margin-right: 5%;
    }
  }

  .form-group_date {
    .v-input__append-inner {
      position: absolute;
      right: 8px;
      pointer-events: none;
      .primary--text {
        color: $accentColor !important;
      }
    }
  }

  .form__teachers {
    margin-top: 20px;
  }

  .form-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    .form-control__left {
      .m-button {
        margin-right: 25px;
      }
    }
  }

  .v-date-picker-table {
    .v-btn--outlined {
      background: $accentColor;
      color: #fff !important;
    }
    .v-btn--active {
      color: $accentColor !important;
      border: thin solid currentColor;
      background-color: #fff !important;
      &:before {
        background: #fff;
      }
    }
  }

  .recurring-event {
    .col {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .recurring-event__add {
    color: $accentColor;
    text-decoration: underline;
    font-weight: 500;
    font-size: 16px;
    &.disabled {
      color: $elementColor;
      pointer-events: none;
    }
    span {
      cursor: pointer;
    }
  }
</style>
